// Internal to module

import { IconsSize } from "./types";

export const sizeToViewBox = (size: IconsSize = IconsSize.Default) => {
  switch (size) {
    case IconsSize.Micro:
      return "0 0 16 16";
    case IconsSize.Mini:
      return "0 0 20 20";
    default:
      return "0 0 24 24";
  }
};

export const sizeToClass = (size: IconsSize = IconsSize.Default) => {
  switch (size) {
    case IconsSize.Micro:
      return "w-5 h-5";
    case IconsSize.Mini:
      return "w-4 h-4";
    default:
      return "w-6 h-6";
  }
};
