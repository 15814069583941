import { type Component, Switch, Match } from "solid-js";
import type { usePromptContext } from "~/domains/chat/prompt/PromptContext";

export const FileUploadProgress: Component<{
  file: ReturnType<ReturnType<typeof usePromptContext>["uploader"]["newManagedAsset"]>;
}> = (props) => {
  return (
    <div class="flex items-center gap-2">
      <div class="relative h-4 w-4 flex-shrink-0 align-top">
        <svg class="size-full" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-indigo-700" stroke-width="5" />
          <g class="origin-center -rotate-90 transform">
            <circle
              cx="18"
              cy="18"
              r="16"
              fill="none"
              class="stroke-current text-violet-400  transition-all duration-300"
              stroke-width="5"
              stroke-dasharray="100"
              stroke-dashoffset={Math.floor(props.file.snapshot.context.progress * 10)}
            />
          </g>
        </svg>
      </div>

      <span class="truncate max-w-full block">
        <span class="pr-1 min-w-6 align-top text-fuchsia-300 leading-normal text-base">
          {Math.floor(props.file.snapshot.context.progress)}%
        </span>
        <span class="align-top leading-normal text-base">{props.file.snapshot.context.uploadFile.name} </span>
        <Switch>
          <Match when={props.file.snapshot.value === "AssetCreationFailed"}>
            <span class="text-red-300 block leading-normal text-base">
              API returned an error when trying to create a new asset record: {props.file.snapshot.context.errorMessage}
            </span>
          </Match>
          <Match when={props.file.snapshot.value === "UploadFailed"}>
            <span class="text-red-300 block leading-normal text-base">
              Error uploading file to storage service: {props.file.snapshot.context.errorMessage}
            </span>
          </Match>
        </Switch>
      </span>
    </div>
  );
};
