import { createBreakpoints } from "@solid-primitives/media";
import { tailwindScreens } from "./tailwind/screens";

export const useBreakpoints = () =>
  createBreakpoints(tailwindScreens, {
    fallbackState: {
      // TODO: Make an educated guess about device size in SSR based on user agent
      // for now assume it's a medium screen
      sm: true,
      md: true,
      lg: false,
      xl: false,
      "2xl": false,
      "3xl": false,
      "4xl": false,
      "5xl": false,
      key: "md",
    },
  });
