import { createSignal } from "solid-js";
import { isServer } from "solid-js/web";

const noSpeech = () => ({
  start: () => {},
  stop: () => {},
  speaking: () => false,
});

export const useSpeechToText = (appendText: (text: string, isFinal: boolean) => void) => {
  if (isServer) return noSpeech();

  const speechApi =
    (typeof SpeechRecognition !== "undefined" && SpeechRecognition) ||
    (typeof webkitSpeechRecognition !== "undefined" && webkitSpeechRecognition);

  if (!speechApi) return noSpeech();

  const [speaking, setSpeaking] = createSignal(false);

  const recog = new speechApi();
  recog.continuous = true;
  recog.interimResults = true;

  recog.addEventListener("start", () => {
    setSpeaking(true);
  });
  recog.addEventListener("error", (event) => {
    console.log(event);
    setSpeaking(false);
  });
  recog.addEventListener("end", () => {
    setSpeaking(false);
  });
  recog.addEventListener("result", (event) => {
    let text = "";
    let isFinal = false;
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      const res = event.results[i];
      if (res?.[0]) {
        text += res[0].transcript;
        isFinal = res.isFinal;
      }
    }
    appendText(text, isFinal);
  });

  const start = () => {
    recog.start();
    recog.lang = "en-US";
  };

  const stop = () => {
    recog.stop();
  };

  return {
    start,
    stop,
    speaking,
  };

  // const SpeechGrammarList =
  //   anyWindow.SpeechGrammarList || anyWindow.webkitSpeechGrammarList;
  // const SpeechRecognitionEvent =
  //   anyWindow.SpeechRecognitionEvent || anyWindow.webkitSpeechRecognitionEvent;
};
