import { type Accessor, createEffect, onCleanup } from "solid-js";
import { driver, type DriveStep, type Config as DriverConfig } from "driver.js";
import "driver.js/dist/driver.css";

export const useHighlightElementOnScreen = (props: {
  ref: Accessor<HTMLElement | undefined>;
  enabled: Accessor<boolean>;
  trackElementPosition?: boolean;
  config?: DriverConfig;
  highlightOpts?: DriveStep;
  onClear: () => void;
}) => {
  let instance: ReturnType<typeof driver> | undefined = undefined;
  let timer: number;

  createEffect(() => {
    const el = props.ref();
    if (!el) return;

    if (props.enabled()) {
      instance = driver({
        overlayColor: window.matchMedia?.("(prefers-color-scheme: dark)").matches ? "#a5b4fc" : "black",
        overlayOpacity: 0.3,
        ...props.config,
      });
      instance.highlight({
        ...props.highlightOpts,
        element: el,
        onDeselected: () => {
          window.clearInterval(timer);
          props.onClear();
        },
      });

      if (props.trackElementPosition) {
        // Not the most performant way to do it, but it's the only reliable way for now
        // Refreshing the overlay every 100ms in case the highlighted element has moved
        timer = window.setInterval(() => {
          if (!instance) {
            window.clearInterval(timer);
            return;
          }
          instance.refresh();
        }, 100);
      }

      onCleanup(() => {
        instance?.destroy();
        instance = undefined;
        window.clearInterval(timer);
      });
    } else {
      instance?.destroy();
      instance = undefined;
      window.clearInterval(timer);
    }
  });
};
