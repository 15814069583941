import type { MotionComponentProps } from "solid-motionone";

export const slashPopupAnimationProps: MotionComponentProps = {
  initial: {
    opacity: 0,
    x: -10,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  transition: {},
};
