import { createSignal, onCleanup, onMount } from "solid-js";

export const useClickOutside = (onClick: () => void, workaroundKobalteCapturing?: boolean) => {
  const [ref, setRef] = createSignal<Element>();

  onMount(() => {
    const listener = (e: MouseEvent) => {
      const r = ref();
      if (!r) return;

      if (e.target instanceof Node && !r.contains(e.target)) {
        // Some of the kobalte click triggers capture events and the target becomes the <html> dom element
        // we use workaroundKobalteCapturing to decide if it triggers the click outside callback
        if (workaroundKobalteCapturing && e.target === window.document.documentElement) return;
        onClick();
      }
    };

    window.addEventListener("click", listener, { capture: true });
    onCleanup(() => {
      window.removeEventListener("click", listener, { capture: true });
    });
  });
  return { setRef };
};
