import { type Component, createEffect, createSignal, onCleanup } from "solid-js";
import { clsx } from "clsx";

export const CircularStatus: Component<{
  progress: number;
  title?: string;
  hasFailed?: boolean;
}> = (props) => {
  const [pulse, setPulse] = createSignal(false);

  createEffect(() => {
    const interval = setInterval(() => {
      props.progress < 100 && setPulse((prev) => !prev);
    }, 500);

    onCleanup(() => clearInterval(interval)); // Cleanup the interval on component unmount
  });

  return (
    <div class={`relative h-4 w-4 ${pulse() ? "animate-pulse" : ""}`} title={props.title}>
      <svg class="size-full" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          class={clsx("stroke-current", {
            "text-violet-700": !props.hasFailed,
            "text-red-700": props.hasFailed,
          })}
          stroke-width="5"
        />
        <g class="origin-center -rotate-90 transform">
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            class={clsx("stroke-current", {
              "text-violet-400": !props.hasFailed,
              "text-red-400": props.hasFailed,
            })}
            stroke-width="5"
            stroke-dasharray="100"
            stroke-dashoffset={props.progress}
          />
        </g>
      </svg>
    </div>
  );
};
