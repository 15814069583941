import Tag from "@tiptap/extension-mention";
import { SolidNodeViewRenderer, SolidRenderer, getTiptapSolidReactiveOwner } from "tiptap-solid";
import { PluginKey } from "prosemirror-state";
import { useWire } from "~/wire";
import { runWithOwner } from "solid-js";
import type { SuggestionProps } from "@tiptap/suggestion";
import { getPromptContextFromEditor, insertCharacterCommand } from "../helpers";
import { stAnalytics } from "@repo/analytics";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { CollectionKind, type CollectionSnapshot } from "~/domains/collections/collections.types";
import {
  CollectionMentionsContainer,
  CollectionMentionsNode,
  type CollectionMentionsProps,
} from "./CollectionMentions.render";

export type CollectionMentionsSuggestionProps = SuggestionProps<CollectionSnapshot>;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    Tag: {
      invokeTag: () => ReturnType;
    };
  }
}

// Workaround for not knowing if the slash command menu was opened by typing or manually invoked through a command
let insertTriggeredManually = false;

export const CollectionMentionsExtension = Tag.extend({
  name: "collectionMentionNode",
  addAttributes() {
    return {
      id: "",
      label: "",
    };
  },
  addNodeView() {
    return SolidNodeViewRenderer(CollectionMentionsNode);
  },
  addCommands: () => ({
    invokeTag: () => {
      insertTriggeredManually = true;
      return insertCharacterCommand("#");
    },
  }),
}).configure({
  suggestion: {
    pluginKey: new PluginKey("collectionMention"),
    char: "#",
    items: async ({ query, editor }): Promise<CollectionSnapshot[]> => {
      const owner = getTiptapSolidReactiveOwner(editor);
      const wire = runWithOwner(owner, useWire);
      const context = getPromptContextFromEditor(editor);
      if (!wire || !context) return [];

      const allCollections = Object.values(wire.services.collections.getAllCollections());

      // TEMP: Filtering out Starred and Shared collections
      return (allCollections || []).filter(
        (item) =>
          item.label.toLowerCase().replaceAll(" ", "").includes(query.toLowerCase()) &&
          item.collectionKind !== CollectionKind.Favorites &&
          item.collectionKind !== CollectionKind.Org,
      );
    },
    render: () => {
      let solidRenderer: SolidRenderer<CollectionMentionsProps> | undefined;
      return {
        onStart: (props: CollectionMentionsSuggestionProps) => {
          solidRenderer = new SolidRenderer(CollectionMentionsContainer, {
            props,
            editor: props.editor,
          });
          solidRenderer.updateProps(props);

          const owner = getTiptapSolidReactiveOwner(props.editor);
          const wire = runWithOwner(owner, useWire);
          const from = insertTriggeredManually ? "toolbar" : "text";
          insertTriggeredManually = false;

          if (!wire) return;

          stAnalytics.track("prompt_tags_menu_opened", {
            from,
            ...getThreadEventProperties({
              workingContext: wire.services.identity.snapshot.context.identity.workingContext,
              threadId: wire.services.threads.snapshot.context.threadId,
              threadMessages: wire.services.threads.messages(),
            }),
          });
        },
        onUpdate(props: CollectionMentionsSuggestionProps) {
          solidRenderer?.updateProps(props);
        },
        onExit() {
          solidRenderer?.destroy();
          solidRenderer = undefined;
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            solidRenderer?.destroy();
            solidRenderer = undefined;
            return true;
          }
          return false;
        },
      };
    },
  },
  renderText({ node, options }) {
    return `${options.suggestion.char}${node.attrs.label}`;
  },
});
