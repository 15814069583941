import { Node } from "@tiptap/core";
import { SolidNodeViewRenderer } from "tiptap-solid";
import { VariablesRenderer } from "./Variables.render";

export const VariablesExtension = Node.create({
  name: "variable",
  group: "inline",
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      label: {
        default: "",
      },
      type: {
        default: "text",
      },
      value: {
        default: "",
      },
      id: {
        default: "",
      },
      options: {
        default: [],
      },
      assetName: {
        default: undefined,
      },
      assetId: {
        default: undefined,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-variable]",
      },
    ];
  },

  addNodeView() {
    return SolidNodeViewRenderer(VariablesRenderer);
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", { "data-variable": "", ...HTMLAttributes }, 0];
  },
});
