import { createEffect, runWithOwner, type Component } from "solid-js";
import type { CollectionMentionsSuggestionProps } from "./CollectionMentions.extension";
import { getTiptapSolidReactiveOwner, NodeViewWrapper } from "tiptap-solid";
import type { NodeViewProps } from "@tiptap/core";
import { HoverCard } from "@kobalte/core";
import { ChatPopupMenu } from "../ChatPopupMenu";
import { Icon, IconName, IconsSize } from "~/components/icons";
import { stAnalytics } from "@repo/analytics";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { useWire } from "~/wire";

export type CollectionMentionsProps = CollectionMentionsSuggestionProps;

export const CollectionMentionsContainer: Component<CollectionMentionsProps> = (props) => {
  return (
    <ChatPopupMenu
      {...props}
      onSelect={(item: CollectionMentionsProps["items"][number]) => {
        // const owner = getTiptapSolidReactiveOwner(props.editor);
        // const wire = runWithOwner(owner, useWire);
        // if (!wire) return;
        // stAnalytics.track("prompt_tag_referenced", {
        //   tagType: "asset",
        //   tagId: item?.id ?? "unknown",
        //   ...getThreadEventProperties({
        //     workingContext: wire.services.identity.snapshot.context.identity.workingContext,
        //     threadId: wire.services.threads.snapshot.context.threadId,
        //     threadMessages: wire.services.threads.messages(),
        //   }),
        // });
      }}
    >
      {(item) => (
        <span class="flex items-center gap-4">
          <Icon name={IconName.Tag} size={IconsSize.Mini} />
          <span>{item.label}</span>
        </span>
      )}
    </ChatPopupMenu>
  );
};

export const CollectionMentionsNode: Component<NodeViewProps> = (props) => {
  return (
    <NodeViewWrapper as="span">
      <span class="rounded px-1 bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-100">
        #{props.node.attrs.label}
      </span>
    </NodeViewWrapper>
  );
};
