import { Model, type curator, ThreadMessageKinds } from "@repo/client";

export const blankKnowledge = Object.freeze({
  assetContext: {
    added: [],
    removed: [],
  },
  projectContext: {
    added: [],
    removed: [],
  },
  directoryContext: {
    added: [],
    removed: [],
  },
  organizationContext: {
    added: [],
    removed: [],
  },
  world: true,
});

export const newTransformationPrompt = (
  prompt: string,
  transformationId: string,
): curator.MessagePromptTransformationV2 => {
  const res: curator.MessagePromptTransformationV2 = {
    kind: ThreadMessageKinds.MessageKindPromptTransformationV2,
    // @ts-ignore - undefined as this should only be set by server
    messageId: undefined,
    prompt,
    scope: {
      worldKnowledge: false,
      collectionIDs: [],
      assetIDs: [],
    },
    model: Model.ModelAuto,
    transformationId,
  };
  return res;
};

export const newPromptWithDefaults = (prompt: string, transformationId: string): curator.MessagePromptV2 => ({
  kind: ThreadMessageKinds.MessageKindPromptV2,
  // @ts-ignore - undefined as this should only be set by server
  messageId: undefined,
  context_window: -1,
  frequency_penalty: -1,
  logit_bias: {},
  max_tokens: -1,
  model: Model.ModelAuto,
  presence_penalty: -1,
  scope: {
    worldKnowledge: false,
    collectionIDs: [],
    assetIDs: [],
  },
  prompt,
  transformationId,
  stop: [],
  temperature: -1,
  top_p: -1,
});
