import { Match, Switch } from "solid-js";
import { IconSVG, isSize } from "./common";
import { IconsSize, type InternalIconProps } from "./types";

export const TextFormatIcon = (props: InternalIconProps) => {
  const size = isSize(props);
  return (
    <IconSVG size={props.size} fill="currentColor">
      <Switch>
        {/* Only micro implementation for now (only used in the chat toolbar) */}
        <Match when={size(IconsSize.Micro)}>
          <path d="M10.9499 11.5C10.5961 11.5 10.2807 11.4386 10.0039 11.3158C9.72703 11.1907 9.50797 11.0065 9.34668 10.7634C9.18779 10.5178 9.10835 10.2121 9.10835 9.84617C9.10835 9.53804 9.16492 9.27925 9.27807 9.06981C9.39121 8.86038 9.54528 8.69186 9.74027 8.56428C9.93526 8.43669 10.1567 8.3404 10.4047 8.2754C10.6551 8.2104 10.9174 8.16466 11.1919 8.13818C11.5145 8.10448 11.7745 8.07318 11.9719 8.0443C12.1693 8.013 12.3125 7.96726 12.4016 7.90708C12.4906 7.84689 12.5352 7.75782 12.5352 7.63987V7.6182C12.5352 7.3895 12.4629 7.21257 12.3185 7.08739C12.1765 6.96221 11.9743 6.89961 11.7119 6.89961C11.435 6.89961 11.2148 6.961 11.0511 7.08378C10.8874 7.20414 10.779 7.3558 10.7261 7.53876L9.30334 7.42321C9.37556 7.08618 9.51759 6.7949 9.72944 6.54935C9.94128 6.3014 10.2145 6.11122 10.5491 5.97882C10.8862 5.84401 11.2761 5.7766 11.7191 5.7766C12.0272 5.7766 12.3221 5.81271 12.6038 5.88493C12.8878 5.95715 13.1394 6.06909 13.3585 6.22075C13.5799 6.37241 13.7545 6.56741 13.8821 6.80573C14.0096 7.04165 14.0734 7.32451 14.0734 7.65431V11.3953H12.6146V10.6261H12.5713C12.4822 10.7995 12.363 10.9523 12.2138 11.0847C12.0645 11.2147 11.8852 11.317 11.6758 11.3917C11.4663 11.4639 11.2244 11.5 10.9499 11.5ZM11.3905 10.4384C11.6168 10.4384 11.8166 10.3938 11.9899 10.3048C12.1632 10.2133 12.2993 10.0905 12.398 9.93645C12.4967 9.78238 12.546 9.60785 12.546 9.41286V8.82427C12.4979 8.85556 12.4317 8.88445 12.3474 8.91093C12.2655 8.935 12.1729 8.95787 12.0694 8.97954C11.9658 8.9988 11.8623 9.01685 11.7588 9.0337C11.6553 9.04815 11.5614 9.06139 11.4772 9.07342C11.2966 9.0999 11.1389 9.14203 11.0041 9.19981C10.8693 9.25758 10.7646 9.33582 10.69 9.43452C10.6153 9.53081 10.578 9.65118 10.578 9.79562C10.578 10.0051 10.6538 10.1651 10.8055 10.2759C10.9596 10.3842 11.1546 10.4384 11.3905 10.4384Z" />
          <path d="M3.67549 11.3953H2L4.55296 4H6.56789L9.11724 11.3953H7.44174L5.58931 5.68994H5.53154L3.67549 11.3953ZM3.57078 8.48844H7.52841V9.70896H3.57078V8.48844Z" />
        </Match>
      </Switch>
    </IconSVG>
  );
};
