import { type Placement, createPopper, type Instance, type VirtualElement } from "@popperjs/core";
import { createSignal, onCleanup, onMount } from "solid-js";

/**
 * Creates a popper pop-up using a virtual element with a stub of the getBoundingClientRect.
 * Used a performance optimization for the SlashCommand pop-up since the target DOM element for the
 * pop-up changes with each editor update. We would have to create a new popper instance for each new DOM element.
 * Using the bouding client rect we can just call instance().update() when it needs to recalculate position.
 */
export const useVirtualElementPopper = ({
  getBoundingClientRect,
  placement,
}: {
  getBoundingClientRect: () => DOMRect | undefined | null;
  placement?: Placement;
}) => {
  const [instance, setInstance] = createSignal<Instance>();
  const [ref, setRef] = createSignal<HTMLElement>();
  const virtualElement: VirtualElement = {
    getBoundingClientRect: () =>
      getBoundingClientRect?.() || {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        toJSON() {},
      },
  };
  const destroy = () => {
    instance()?.destroy();
  };
  onMount(() => {
    const el = ref();
    if (!el) return;

    const instance = createPopper(virtualElement, el, {
      placement: placement ?? "right-end",
      modifiers: [
        { name: "eventListeners", enabled: false },
        {
          name: "offset",
          options: {
            offset: ({ placement }: { placement: string }) => {
              if (placement.includes("right") || placement.includes("left")) {
                return [0, 10];
              }
              return [10, 0];
            },
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top", "left", "right", "bottom"],
          },
        },
      ],
    });
    setInstance(instance);

    onCleanup(() => {
      destroy();
    });
  });

  return {
    popperInstance: instance,
    updatePopperPosition: () => instance()?.update(),
    setPopperRef: setRef,
    destroyPopperInstance: destroy,
  };
};
