import { stAnalytics } from "@repo/analytics/src/analytics";
import { HiSolidArrowTopRightOnSquare } from "solid-icons/hi";
import type { Component } from "solid-js";
import { StIcon } from "~/components/icons";
import { Tooltip, TooltipTrigger } from "~/components/popups/Tooltip";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useUIState } from "~/ui/UIState";
import { usePromptContext } from "../PromptContext";
import styles from "./ChatScope.module.css";

export const ChatScope: Component<{ test?: true }> = (props) => {
  const ui = useUIState();
  const { promptRef } = usePromptContext();
  const [usePublicKnowledge, setUsePublicKnowledge] = ui.usePublicKnowledge;

  return (
    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    <div class={styles["parent"]}>
      <Tooltip
        mount={promptRef()}
        theme="invert"
        content={
          <>
            <p class="max-w-64">
              Public knowledge will layer in the LLM’s foundational training knowledge in addition to answering with
              your uploaded assets.
            </p>
            <a
              class="inline-block mt-2 hover:underline"
              href="http://docs.storytell.ai/under-the-hood/public-knowledge"
              target="_blank"
              rel="noreferrer"
            >
              <span class="screen-reader">Open documentation to</span>
              <span class="text-blue-600 font-bold">Learn more</span>
              <StIcon icon={HiSolidArrowTopRightOnSquare} class="inline-block ml-1 text-blue-600" size={12} />
              <span class="screen-reader">in a new window</span>
            </a>
          </>
        }
        placement="top"
      >
        <TooltipTrigger>
          <SwitchToggle label="Public knowledge" onToggle={setUsePublicKnowledge} checked={usePublicKnowledge()} />
        </TooltipTrigger>
      </Tooltip>
    </div>
  );
};

interface SwitchToggleProps {
  checked: boolean;
  onToggle?: (checked: boolean) => void;
  initialState?: boolean;
  disabled?: boolean;
  label?: string;
  labelPosition?: "left" | "right";
}

const SwitchToggle = (props: SwitchToggleProps) => {
  const handleToggle = () => {
    if (!props.disabled) {
      const newState = !props.checked;
      stAnalytics.track("click_tracking", {
        cta: "knowledge_scope",
        position: "prompt_bar",
        screen: getScreenType(),
        value: props.checked ? "public" : "private",
      });
      props.onToggle?.(newState);
    }
  };

  const labelPos = props.labelPosition ?? "left";

  return (
    <label
      class={styles.container}
      // biome-ignore lint/a11y/noNoninteractiveElementToInteractiveRole: <explanation>
      role="switch"
      tabIndex={0}
      aria-checked={props.checked}
    >
      {props.label && labelPos === "left" && <span class={styles.label}>{props.label}</span>}
      <span class={`${styles.switch} ${props.disabled ? styles.disabled : ""}`}>
        <input
          type="checkbox"
          checked={props.checked}
          onInput={handleToggle}
          disabled={props.disabled}
          class={styles.input}
        />
        <span class={styles.slider} />
      </span>
      {props.label && labelPos === "right" && <span class={styles.label}>{props.label}</span>}
    </label>
  );
};

export default SwitchToggle;
