import { createDropzone } from "@solid-primitives/upload";
import { type Component, For, createEffect } from "solid-js";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { FileUploadProgress } from "./FileUploadProgress";

export type FileDropzoneProps = {
  /**
   * Callback to close the file dropzone.
   */
  close: () => void;
  /**
   * When 'thread', we will ask the server to process the file in the foreground, triggered by a knowledge change event
   * within the thread. This will allow the user to see the progress of the file processing as the server streams
   * messages to the thread. This would be the correct mode when the user is adding knowledge to the thread and wants to
   * chat about that knowledge immediately.
   *
   * When 'project', we will ask the server to process the file in the background, triggered as soon as we notify the
   * server that the file has been uploaded. The user will not see the progress of the file processing. This would be
   * the correct mode when the user is simply adding knowledge to their project without an immediate need to chat with
   * that new knowledge.
   */
  context: "thread" | "project";
};

export const FileDropzone: Component<FileDropzoneProps> = (props) => {
  const { uploader, activeCollection } = usePromptContext();

  createEffect(() => {
    uploader.onConfirm(props.context === "thread");
    const assets = uploader.assets();
    if (assets.length && assets.every((a) => a.snapshot.value === "Done")) {
      props.close();
      uploader.onReset();
    }
  });

  return (
    <div class="flex flex-col gap-2 w-full h-full">
      <div class="flex flex-col gap-2 min-w-[30vw]">
        <For each={uploader.assets()}>{(file) => <FileUploadProgress file={file} />}</For>
      </div>

      <button
        onClick={() => {
          const col = activeCollection();
          if (!col) {
            throw Error("No active collection.");
          }
          uploader.onSelectFiles(col.id, col.organizationId, col.tenantId);
        }}
        type="button"
        class="flex flex-col items-center justify-center border-2 border-indigo-700 dark:border-slate-600 hover:bg-indigo-900/30 dark:hover:bg-slate-400/5 border-dashed rounded-lg cursor-pointer"
      >
        <div class="flex flex-col items-center justify-center pt-5 pb-6 px-6">
          <svg
            class="w-10 h-10 mt-3 mb-3 text-indigo-300"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <p class="mb-2 text-white text-lg">
            <span class="font-semibold">Click to upload</span> or drag and drop
          </p>
        </div>
        <AllowedFiles />
      </button>
    </div>
  );
};

const AllowedFiles = () => {
  return (
    <div class="flex flex-col items-center pt-4 pb-4 px-4">
      <ul class="flex flex-wrap gap-4 justify-center mb-4 text-indigo-300">
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] rounded block px-1 top-4 text-[9px] bg-red-600">PDF</span>
        </li>
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-orange-600">
            PPT
          </span>
        </li>
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-black">MD</span>
        </li>
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-black">XML</span>
        </li>
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] rounded block px-1 top-4 text-[9px] bg-black">TXT</span>
        </li>
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-black">VTT</span>
        </li>
        {/*</ul>*/}
        {/*<ul class="flex gap-4 justify-center w-[50%]">*/}
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-green-600">CSV</span>
        </li>
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-green-600">XLS</span>
        </li>

        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-blue-600">DOC</span>
        </li>
        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-purple-600">
            MP3
          </span>
        </li>

        <li class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#C8D2E1" viewBox="0 0 256 256">
            <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />
          </svg>
          <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-purple-600">
            MP4
          </span>
        </li>

        {/*<li class="relative">*/}
        {/*  <svg*/}
        {/*    xmlns="http://www.w3.org/2000/svg"*/}
        {/*    width="40"*/}
        {/*    height="40"*/}
        {/*    fill="#C8D2E1"*/}
        {/*    viewBox="0 0 256 256"*/}
        {/*  >*/}
        {/*    <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z" />*/}
        {/*  </svg>*/}
        {/*  <span class="absolute text-center min-w-[20px] block px-1 top-4 text-[9px] text-white bg-yellow-600">*/}
        {/*    ZIP*/}
        {/*  </span>*/}
        {/*</li>*/}
      </ul>
      <p class="mt-3 text-indigo-200 max-w-80 text-xs">
        We support hundreds of file formats.
        <br />
        <a
          href="https://go.storytell.ai/file-types"
          onClick={(e) => e.stopImmediatePropagation()}
          target="_storytell_docs"
          class="underline"
          rel="noreferrer"
        >
          See the full list of supported files
        </a>
        .
      </p>
    </div>
  );
};
