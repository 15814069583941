import type { ScreenType } from "typedefs/generic-exports";
import { SCREEN_TYPE } from "~/ui/global-listeners";

export function getScreenType(): ScreenType | "other" {
  const attributes = Object.keys(SCREEN_TYPE) as ScreenType[];
  for (const attribute of attributes) {
    if (document.documentElement.getAttribute(SCREEN_TYPE[attribute]) === "true") {
      return attribute;
    }
  }
  return "other";
}
