import { type Editor, Extension } from "@tiptap/core";
import { getPromptContextFromEditor } from "./helpers";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    ChatSettings: {
      /**
       * Comments will be added to the autocomplete.
       */
      insertNewline: () => ReturnType;
    };
  }
}

export const ChatSettingsExtension = Extension.create({
  name: "ChatSettings",
  addCommands() {
    return {
      insertNewline:
        () =>
        ({ commands }) =>
          commands.first(({ commands }) => [
            () => commands.newlineInCode(),
            () => commands.splitListItem("listItem"),
            () => commands.createParagraphNear(),
            () => commands.liftEmptyBlock(),
            () => commands.splitBlock(),
          ]),
    };
  },
  addKeyboardShortcuts() {
    return {
      "Mod-Enter": ({ editor }) => {
        const context = getPromptContextFromEditor(editor);
        context?.submitPrompt();
        return true;
      },
      "Shift-Enter": ({ editor }) => {
        const context = getPromptContextFromEditor(editor);
        if (context?.promptSettings.submitKeybinding === "enter") {
          return editor.commands.insertNewline();
        }
        if (context?.promptSettings.submitKeybinding === "shift+enter") {
          context?.submitPrompt();
          return true;
        }
        return true;
      },
      Enter: ({ editor }) => {
        const context = getPromptContextFromEditor(editor);
        if (context?.promptSettings.submitKeybinding === "shift+enter") {
          return editor.commands.insertNewline();
        }

        if (context?.promptSettings.submitKeybinding === "enter") {
          context?.submitPrompt();
          return true;
        }
        return true;
      },
    };
  },
});
