import type { JSONContent } from "@tiptap/core";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Responsible for triggering a "typing" animation in the prompt bar
 * Given a prompt, it will go through all the text and non-text content and insert each one
 * sequentially with some delay between them, creating the illusion of the prompt being typed in
 *
 * If it's been half a second since the prompt animation started and there is still content to type out,
 * the rest of the content will be added instantaneously to the prompt bar
 */
export const insertPromptWithAnimation = async (
  content: string | JSONContent,
  insertContent: (value: string | JSONContent) => void,
  setContent: (value: string | JSONContent) => void,
): Promise<void> => {
  if (typeof content === "string") {
    await insertPromptWithAnimation(
      {
        type: "doc",
        content: [{ type: "paragraph", content: [{ type: "text", text: content }] }],
      },
      insertContent,
      setContent,
    );
    return;
  }

  const start = performance.now();
  const delayMs = 15;
  const hasTimedOut = () => {
    return performance.now() - start >= 500;
  };

  for (const node of content.content || []) {
    // If timeout exceeded, set the rest of the content in the prompt bar without an animation
    if (hasTimedOut()) {
      setContent(content);
      return;
    }

    // We're not supporting typing animation for other
    // nodes apart from paragraphs for now
    if (node.type !== "paragraph") {
      insertContent(node);
      await delay(delayMs);
      continue;
    }

    insertContent({ type: "paragraph", content: [] as JSONContent[] });
    for (const inlineNode of node.content || []) {
      if (hasTimedOut()) {
        setContent(content);
        return;
      }

      if (inlineNode.type !== "text") {
        insertContent(inlineNode);
        await delay(delayMs);
        continue;
      }

      // If node type is text, add each character individually to create a typing effect
      for (let i = 0; i <= (inlineNode.text?.length || 0); i++) {
        if (hasTimedOut()) {
          setContent(content);
          return;
        }
        insertContent(inlineNode.text?.substring(i, i + 1) || "");
        await delay(delayMs);
      }
    }

    await delay(delayMs);
  }
};
