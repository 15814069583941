import { Placeholder as TiptapPlaceholder } from "@tiptap/extension-placeholder";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    placeholder: {
      /**
       * Set the placeholder value
       */
      setPlaceholder: (placeholder: string) => ReturnType;
    };
  }
}

export const Placeholder = TiptapPlaceholder.extend({
  addCommands() {
    return {
      setPlaceholder: (placeholder: string) => () => {
        this.options.placeholder = placeholder;
        this.editor.view.dispatch(this.editor.view.state.tr);
        return true;
      },
    };
  },
});
